export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')];

export const server_loads = [0,3];

export const dictionary = {
	"/": [~8],
	"/(transition)/(main)/activate": [~9,[2,3]],
	"/(transition)/auth/reset-password": [20,[2,7]],
	"/(transition)/auth/reset-password/sent": [22,[2,7]],
	"/(transition)/auth/reset-password/[reset_token]": [~21,[2,7]],
	"/(transition)/auth/sign-in": [~23,[2,7]],
	"/(transition)/auth/sign-out": [24,[2,7]],
	"/(transition)/auth/sign-up": [~25,[2,7]],
	"/(transition)/(main)/availability": [~10,[2,3]],
	"/(transition)/(main)/character-assessment/(paginated)": [~11,[2,3,4]],
	"/(transition)/(main)/character-assessment/complete": [~15,[2,3]],
	"/(transition)/(main)/character-assessment/(paginated)/finish": [~14,[2,3,4]],
	"/(transition)/(main)/character-assessment/(paginated)/[section]": [~12,[2,3,4]],
	"/(transition)/(main)/character-assessment/(paginated)/[section]/[step]": [~13,[2,3,4]],
	"/(transition)/(main)/dashboard": [~16,[2,3]],
	"/(transition)/(main)/profile/(paginated)": [~17,[2,3,5]],
	"/(transition)/(main)/profile/(paginated)/[section]": [~18,[2,3,5]],
	"/(transition)/(main)/psyche": [~19,[2,3,6]],
	"/(transition)/support": [26,[2]]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';